<template>
  <div class="height_100">
    <el-container class="height_100">
      <el-aside width="235px" class="left" style="background-color: #2e3751">
        <el-menu
          :default-active="num"
          class="el-menu-vertical-demo"
          background-color="#2e3751"
          text-color="#fff"
          active-text-color="#0d8eff"
        >
          <el-menu-item index="2" @click='goHome' style="background:#262E45;text-align:center;font-size:20px; height:70px;line-height:70px;">
            <span slot="title">网站首页</span>
          </el-menu-item>
          <div v-if="type == 1">
            <el-menu-item index="4" @click="goCenter" class='menu_class'>
              <i class="el-icon-user fff" style="font-size: 20px;"></i>
              <span slot="title" style="font-size: 15px;">个人中心</span>
            </el-menu-item>
            <!-- <el-menu-item index="6" @click="goCertification" class='menu_class'>
              <i class="el-icon-s-platform fff" style="font-size: 20px;"></i>
              <span slot="title" style="font-size: 15px;">企业认证</span>
            </el-menu-item> -->
            <el-menu-item index="8" @click="goDemandList" class='menu_class'>
              <i class="el-icon-menu fff" style="font-size: 20px;"></i>
              <span slot="title" style="font-size: 15px;">我的融资需求列表</span>
            </el-menu-item>
            <el-menu-item index="10" @click="goProductList" class='menu_class'>
              <i class="el-icon-s-finance fff" style="font-size: 20px;"></i>
              <span slot="title" style="font-size: 15px;">我申请的产品列表</span>
            </el-menu-item>
          </div>
          <div v-if="type == 2">
            <el-menu-item index="12" @click="goInstitutionalCenter" class='menu_class'>
              <i class="el-icon-user fff" style="font-size: 20px;"></i>
              <span slot="title" style="font-size: 15px;">机构个人中心</span>
            </el-menu-item>
            <el-menu-item index="14" @click="goGetDemand" class='menu_class'>
              <i class="el-icon-menu fff" style="font-size: 20px;"></i>
              <span slot="title" style="font-size: 15px;">收到的融资需求({{demand_count}})</span>
            </el-menu-item>
            <el-menu-item index="16" @click="goProductApplication" class='menu_class'>
              <i class="el-icon-s-finance fff" style="font-size: 20px;"></i>
              <span slot="title" style="font-size: 15px;">收到的产品申请({{ask_count}})</span>
            </el-menu-item>
            <el-menu-item index="18" @click="goInformation" class='menu_class'>
              <i class="el-icon-s-grid fff" style="font-size: 20px;"></i>
              <span slot="title" style="font-size: 15px;">机构信息认证</span>
            </el-menu-item>
            <el-menu-item index="20" @click="goManagement" class='menu_class'>
              <i class="el-icon-s-data fff" style="font-size: 20px;"></i>
              <span slot="title" style="font-size: 15px;">产品管理</span>
            </el-menu-item>
            <el-menu-item index="22" @click="goPool" class='menu_class'>
              <i class="el-icon-s-cooperation fff" style="font-size: 20px;"></i>
              <span slot="title" style="font-size: 15px;">需求池</span>
            </el-menu-item>
          </div>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header
          style="
            height: 72px;
            line-height: 72px;
            border-bottom: 1px solid #e6e6e6;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #2E3751;
          "
        > 
          <div style="font-size: 20px; font-weight: bold; color: #fff;" v-if='type == 1'>青海省工业小微企业融资服务平台-企业个人中心</div>
          <div style="font-size: 20px; font-weight: bold; color: #fff;" v-if='type == 2'>青海省工业小微企业融资服务平台-机构个人中心</div>
          <div class="flex end aline_center">
            <div style="color: #fff; font-size: 14px; margin-right: 18px; cursor: pointer;" @click='goHome'>(返回首页)</div>
            <div class="flex aline_center">
              <i class="el-icon-user-solid" style='color: #fff; font-size: 17px;'></i>
              <div style='padding: 0 12px; color: #fff; font-size: 14px;'>{{title}}</div>
            </div>
            <div style="margin-left: 20px; color: #fff; cursor: pointer; margin-right: 30px; font-size: 14px;" @click='loggin'>退出</div>
            
          </div>
        </el-header>
        <el-main>
          <router-view v-if="this.$route.path != '/PersonalCenter'"/>
          <div v-if="type == 1 && this.$route.path == '/PersonalCenter'">
            <div>
              <div class="flex">
                <div class="width_50 mar_40">
                  <div class="bor_b">
                    <div
                      class="size_20 main_text_color"
                      style="
                        border-bottom: 2px solid #0d8eff;
                        display: inline-block;
                        line-height: 50px;
                      "
                    >
                      我发布的需求
                    </div>
                  </div>
                  <div
                    class="mat_20 size_18"
                    style="background: #fafafa; height: 468px; padding: 12px 30px;"
                  >
                    <div
                      class="bor_b flex bewteen"
                      v-for="(item, index) in result.finance_demand"
                      :key="index"
                      style='padding: 16px 0;'
                      @click='toDemandList'
                    >
                      <div class="person_font" style="flex: 2;">
                        <span>编号：</span>
                        <span>{{item.mark}}</span>
                      </div>
                      <div class="person_font" style="text-align: left; flex: 1;">
                        <span>拟融额度：</span>
                        <span class="ffa">{{item.quota}}万</span>
                      </div>
                      <div class="person_font" style="text-align: right; flex: 1;">
                        <span>状态：</span>
                        <span v-if="item.status == 1">未匹配</span>
						<span v-if="item.status == 2">已受理</span>
                        <span v-if="item.status == 3">待预授信</span>
                        <span v-if="item.status == 4">待授信</span>
                        <span v-if="item.status == 5">拒绝授信</span>
                        <span v-if="item.status == 6">已授信</span>
                        <span v-if="item.status == 7">已完成</span>
                      </div>
                    </div>

                    <el-empty description="暂无数据" v-if='result.finance_demand.length == 0' style='height: 100%;'></el-empty>
                  </div>
                </div>
                <div class="width_50">
                  <div class="flex bewteen aline_center bor_b main_text_color">
                    <span
                      class="size_20"
                      style="
                        border-bottom: 2px solid #0d8eff;
                        line-height: 50px;
                      "
                      >我申请的产品</span
                    >
                    <span @click='toProductList' style="cursor: pointer;">查看更多>></span>
                  </div>
                  <div
                    class="mat_20 size_18"
                    style="background: #fafafa; height: 468px; padding: 12px 30px;"
                  >
                    <div
                      class="bor_b flex bewteen"
                      v-for="(item, index) in result.product_ask"
                      :key="index"
                      style='padding: 16px 0;'
                      @click='toProductList'
                    >
                      <div>
                        <span>编号：</span>
                        <span>{{item.mark}}</span>
                      </div>
                      <div class="main_text_color">
                        {{item.name}}
                      </div>
                      <div>
                        <span>状态：</span>
                        <span v-if="item.status == 1"> 待审核</span>
                        <span v-if="item.status == 2"> 审核未通过</span>
                        <span v-if="item.status == 3"> 已审核</span>
                        <span v-if="item.status == 4"> 已驳回</span>
                        <span v-if="item.status == 5"> 已授信</span>
                        <span v-if="item.status == 6"> 已完成</span>
                      </div>
                    </div>

                    <el-empty description="暂无数据" v-if='result.product_ask.length == 0' style='height: 100%;'></el-empty>
                  </div>
                </div>
              </div>

              <div class="mat_30">
                <div class="flex bewteen aline_center bor_b main_text_color">
                  <span
                    class="size_20"
                    style="border-bottom: 2px solid #0d8eff; line-height: 50px"
                    >推荐产品</span
                  >
                  <span @click='toFinancialproducts' style='cursor: pointer;'>查看更多>></span>
                </div>
                <div class="mat_30 flex bewteen">
                  <div
                    class="bg_fa padlr2_tb3 personc"
                    v-for="(item, index) in result.product"
                    :key="index"
                    @click='toProductsdetail(item.id)'
                  >
                    <div style="font-size: 18px;">{{item.name}}</div>
                    <div class="mat_20" style="color: #808080; font-size: 15px;">融资额度：{{item.min_quota}}~{{item.max_quota}}万</div>
                    <div class="mat_10" style="color: #808080; font-size: 15px;">贷款利率：{{item.min_rate}}%~{{item.max_rate}}%</div>
                    <div class="mat_10" style="color: #808080; font-size: 15px;">申请次数：{{item.req_num}}次</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      asidelist: [
        "网站首页",
        "个人中心",
        // "企业认证",
        "我的融资需求列表",
        "我申请的产品列表",
      ],
      current: 1,
      type:null,
      result:{},
      num:null,
      title: '',
      ask_count: '',
      demand_count: '',
      token: localStorage.eleToken
    };
  },
  mounted() {

    this.getVali()
    this.companyIndexApi()

    this.getDemandNum()

    this.init()
  },
  methods: {
     getDemandNum() {
        let data = {token: localStorage.eleToken ? localStorage.eleToken : ''}

        this.$get('/ask_demand_num', data).then(res => {
            if(res.data.status == 1) {
                this.ask_count = res.data.result.ask_count
                this.demand_count = res.data.result.demand_count
            }
        })
    },
    init() {
      if(this.$route.path == '/PersonalCenter') {
        this.num = '4'
      }
      if(this.$route.path == '/enterprisecertification') {
        this.num = '6'
      }
      if(this.$route.path == '/demandList') {
        this.num = '8'

      }
      if(this.$route.path == '/productList') {
        this.num = '10'
      }
      if(this.$route.path == '/InstitutionalCenter') {
        this.num = '12'
      }
      if(this.$route.path == '/getDemand') {
        this.num = '14'
      }
      if(this.$route.path == '/productApplication') {
        this.num = '16'
      }
      if(this.$route.path == '/information') {
        this.num = '18'
      }
      if(this.$route.path == '/management') {
        this.num = '20'
      }
      if(this.$route.path == '/pool') {
        this.num = '22'
      }
    },
    // 用户信息
    getVali() {
      let data = {token: localStorage.eleToken ? localStorage.eleToken : ''}

      this.$post('/vali', data).then(res => {
         if(res.data.status == 1) {
            this.type = res.data.result.type

            this.title = res.data.result.name
         }
      })
    },
    goCenter() {
      this.$router.push("/PersonalCenter");
    },
    // goCertification() {
    //   this.$router.push("/enterprisecertification");
    // },
    goDemandList() {
      this.$router.push("/demandList");
    },
    toProductList() {
      this.$router.push("/productList")
      this.num = '10'
    },
    toDemandList() {
      this.$router.push("/demandList")
      this.num = '8'
    },
    toFinancialproducts() {
      this.$router.push("/financialproducts")
    },
    toProductsdetail(id) {
      this.$router.push({path: 'productsdetail', query: {ids: id, type: 2}})
    },
    goProductList() {
      this.$router.push("/productList");
    },
    goInstitutionalCenter() {
      this.$router.push("/InstitutionalCenter");
    },
    goGetDemand() {
      this.getDemandNum()
      this.$router.push("/getDemand");
    },
    goProductApplication() {
      this.getDemandNum()
      this.$router.push("/productApplication");
    },
    goInformation() {
      this.$router.push("/information");
    },
    goManagement() {
      this.$router.push("/management");
    },
    goPool() {
      this.$router.push("/pool");
    },
    goHome() {
      this.$router.push('/')
    },
    companyIndexApi() {
      let data = {} 
      data.token = localStorage.eleToken
      this.$get('/company_index', data).then(res => {
					console.log(res);
          this.result = res.data.result
				})
    },
    // 退出
    loggin() {
      this.$confirm('是否退出登录?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
        }).then(() => {
          this.$get(
            "http://zk.zhenchangkj.com/v1/logout?token="+this.token,
          ).then((res) => {
			console.log(res)
            this.$message({
              type: "success",
              message: "已退出",
            });
          });
              //  location.href ='http://sso.smeqh.cn:9000/sso/auth?redirect='+encodeURIComponent(location.href)+'?back=' + encodeURIComponent(location.href) + '&origin='+2
               this.$router.replace('/')
              // this.$router.replace('/')
              // if(this.$route.path == '/') {
              //   this.$router.go(0)
              // }else {
              //   this.$router.replace('/')
              // }
              localStorage.clear();

        }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消'
              })        
        })
    }
  },
  watch: {
      $route:{
          handler(route) {
            console.log('route',route)
            this.init()
         }
      }
  }
};
</script>

<style scoped>
.menu_class{
  height: 60px;
  line-height: 60px;
}
.personc{
  width: 19%;
  margin-right: 1.25%
}
.personc:nth-child(5){
  margin-right: 0;
}


/deep/.el-menu-item.is-active {
  height: 60px;
  color: #fff!important;
  background-color: #0D8EFF!important;
}
/deep/.el-menu{
  border-right: none;
}
</style>